a {
    text-decoration: none;
}

a:visited {
    color: rgb(172, 172, 172);
    text-decoration: none;
}


.header a:hover{
    color: rgb(245, 245, 245);
}

body {
    margin: 0;
    background-color: #1E1E1E;
    color: whitesmoke;
}

.smartDirection {
    flex-direction: row;
}

@media only screen and (max-width: 768px) {
    .smartDirection {
        flex-direction: column;
    }
}